import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Photography and Design, Web and Mobile Application Development</h1>
    <p>Our new site is under development.</p>
    <p>
      For any queries in the meantime please contact{" "}
      <a href="mailto:mail@south-systems.co.uk">mail@south-systems.co.uk</a>
    </p>
  </Layout>
)

/*
import { Link } from "gatsby"
import Image from "../components/image"
<div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link>

*/

export default IndexPage
